import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),

  container: {
    marginTop: 60,
    marginBottom: 120,
  },

  content: {
    maxWidth: 850,
    margin: '0 auto',
    color: '#434343',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 300,
    '& a': {
      fontWeight: 500,
    },
    '& ul': {
      marginTop: 8,
      marginBottom: 30,
    },
    '& h1': {
      fontSize: theme.typography.pxToRem(36),
    },
    '& h2': {
      color: '#0E0918',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(24),
      margin: '30px 0',
    },
    '& h3': {
      color: '#141414',
      fontSize: theme.typography.pxToRem(18),
      fontStyle: 'normal',
      fontWeight: 500,
    },
    '& .entry-content': {
      margin: '30px 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 16,
    },
  },

  post: {
    padding: '60px 0',
    borderTop: '1px solid #E8E8E8',
    '&:first-child': {
      borderTop: 0,
      paddingTop: 0,
    },
  },

});

export default styles;

/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Seo, Footer, PageHeader } from 'components/common';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { siteUrl } from 'configs/constants';
import styles from '../../../components/releaseNotesStyles';

function ReleaseNotes({ classes, data }) {
  const releaseNotes = get(data, 'allMarkdownRemark.edges', []);

  const renderReleaseNote = (releaseNote, index) => {
    const { node: { html, frontmatter } } = releaseNote;

    return (
      <div className={classes.post} key={`group-${index}`}>
        <h3>{frontmatter.name}</h3>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  };

  return (
    <>
      <Seo
        title="Release Notes"
        url={`${siteUrl}/release-notes`}
      />
      <PageHeader />
      <section className={classes.container}>
        <div className={classes.content}>
          {releaseNotes.map(renderReleaseNote)}
        </div>
      </section>
      <Footer />
    </>
  );
}

ReleaseNotes.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({

  }).isRequired,
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          html
          frontmatter {
            name
            date
          }
        }
      }
    }
  }
`;

export default withStyles(styles, { withTheme: true })(ReleaseNotes);
